// @ts-nocheck
import React from 'react';
// Customizable Area Start
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton, Button, ThemeProvider, CssBaseline } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import Layout from '../../../ss-cms-common-components/src/Layout';
import { HeaderToastProvider } from '../../../ss-cms-common-components/src/Snackbar/HeaderToastContext';
import { getStorageData, setStorageData } from 'framework/src/Utilities';
// import "bootstrap/dist/css/bootstrap.min.css";
import './assets/App.css';
// import "./assets/fontastic/styles.css";
// Customizable Area End

// Customizable Area Start
function withLayout(OriginalComponent, hideLayout = false) {
  return (props) => {
    const [isUploadCsvAlertVisible, setIsUploadCsvAlertVisible] = React.useState(false);
    const navigate = (to, params) => {
      if (params && params.id) {
        Object.values(params).forEach((param) => (to += '/' + param));
      }
      props.history.push('/' + to);
    };

    const getParam = (param, alternative) => {
      return props.match.params[param] || alternative;
    };

    const goBack = () => {
      props.history.goBack();
    };

    async function isAuthenticated() {
      const admin = await getStorageData('adminuser');
      console.log(admin, 'data27');
      if (
        !admin &&
        !window.location.pathname.includes('/admin/EmailAccountLogin')
      ) {
        props.history.push('/admin/EmailAccountLogin');
      }
    }

    isAuthenticated();

    async function checkCsvUploadStatus() {
      const isUploadCsvInProgress = await getStorageData('isUploadCsvInProgress');
      const csvUploadStartTime = await getStorageData('csvUploadStartTime');

      if (isUploadCsvInProgress !== "true" && (csvUploadStartTime === "null" || csvUploadStartTime === null)) {
        setIsUploadCsvAlertVisible(false);
        return;
      }
      
      const currentTime = new Date().getTime();
      const timeDiff = currentTime - csvUploadStartTime;
      if (csvUploadStartTime && timeDiff > 60000) {
        setIsUploadCsvAlertVisible(true);
        setStorageData('csvUploadStartTime', "null");
      }
    }

    setInterval(() => {
      checkCsvUploadStatus();
    }, 5000);

    return (
      <>
        {/* <ThemeProvider theme={themeWithResponsiveFontSizes}> */}
        <CssBaseline />
        <HeaderToastProvider>
          <Layout hideLayout={hideLayout}>
            <OriginalComponent
              {...props}
              navigation={{ navigate, getParam, goBack }}
            />
          </Layout>
        </HeaderToastProvider>
        <Dialog
          open={isUploadCsvAlertVisible}
          onClose={() => setIsUploadCsvAlertVisible(false)}
        >
          <DialogTitle id="csv-alert-dialog-title">
            Upload status update
          </DialogTitle>
          <IconButton
            id="csv-alert-dialog-close"
            onClick={() => setIsUploadCsvAlertVisible(false)}
          >
            <CloseRounded fontSize="large" />
          </IconButton>
          <DialogContent id="csv-alert-dialog-content">
            <DialogContentText id="csv-alert-dialog-description">
              Processing is taking longer than usual. Feel free to explore other tasks or check back later.
            </DialogContentText>
          </DialogContent>
          <DialogActions id="csv-alert-dialog-actions">
            <Button onClick={() => setIsUploadCsvAlertVisible(false)} autoFocus variant="contained" color="primary">
              Okay
            </Button>
          </DialogActions>
        </Dialog>
        {/* </ThemeProvider> */}
      </>
    );
  };
}
// Customizable Area End

// Customizable Area Start

// const rubik = {
//   fontFamily: 'Rubik',
//   fontStyle: 'normal',
//   fontDisplay: 'swap',
//   fontWeight: 300,
//   // src: `
//   //   local('Rubik'),
//   //   local('Rubik-Regular'),
//   //   url(${RubikRegular}) format('ttf')`,
// };
export const COLOR_PALETTE = {
  palette: {
    type: 'light',
    white: '#fff',
    black: '#000',
    primary: {
      main: '#6200EA',
      contrastText: '#fff',
    },
    secondary: {
      main: '#00D659',
      contrastText: '#3C3E49',
    },
    text: {
      primary: '#676B7E',
      secondary: '#AFB2C0',
      blue: '#3a3f63',
      blue2: 'rgba(72, 100, 132, 1)',
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
    error: {
      main: '#FEA075', // orange
      // #ffa175
    },
    warning: {
      main: '#FCC65A', //'yellow'
    },
    info: {
      main: '#345E7D', // blue
    },
    success: {
      main: '#54A4A6', // cyan
    },
    alert: {
      main: '#E10707',
    },
    // error: {
    //   main: '#ef3e22'
    // }
    // #3a3f63
    // #272b2b
  },
};
const theme = createTheme({
  ...COLOR_PALETTE,
  typography: {
    fontFamily: 'Rubik',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          {
            fontFamily: 'Rubik',
            fontStyle: 'normal',
            fontDisplay: 'swap',
            fontWeight: 300,
          },
        ],
      },
    },
    MuiButton: {
      textPrimary: {
        textTransform: 'capitalize',
        textDecoration: 'underline',
        fontSize: '12px',
        color: '#3C3E49',
        fontWeight: '400',
        cursor: 'pointer',
      },
      containedPrimary: {
        textTransform: 'capitalize',
        padding: '8px 32px',
        borderRadius: '3px',
        backgroundColor: '#00D659',
        color: '#3C3E49',
        fontSize: '16px',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '128px',
        '&:hover': {
          backgroundColor: '#00D659',
          color: '#3C3E49',
        },
      },
      startIcon: {
        marginRight: '2px',
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '24px',
        color: '#3C3E49',
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$multiline': {
          paddingRight: '4px',
          paddingBottom: '4px',
        },
        '&$disabled $notchedOutline': {
          background: 'rgba(20,20,20,0.05)',
        },
        '&$error $notchedOutline': {
          borderColor: 'D50000',
        },
      },
    },
    MuiInputLabel: {
      root: {
        '&$focused': {
          color: 'black',
        },
        '&$error': {
          color: 'black',
        },
        '&$disabled': {
          color: '#83889E',
        },
      },
      asterisk: {
        '&$focused': {
          color: 'black',
        },
        '&$error': {
          color: 'black',
        },
        '&$disabled': {
          color: '#83889E',
        },
      },
    },
    MuiFormHelperText: {
      root: {
        '&.Mui-error': {
          color: '#D50000',
        },
      },
      contained: {
        marginLeft: 0,
      },
    },
  },
});

const themeWithResponsiveFontSizes = responsiveFontSizes(theme);

export default withLayout;
// Customizable Area End
